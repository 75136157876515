import { forwardRef, memo, ComponentPropsWithRef, ReactNode } from 'react';

import { Empty, Checked } from './icon';

import { StyledInput, StyledBox, StyledLabel } from './CheckBox.styles';

export type CheckBoxProps = {
  label: ReactNode;
  primary?: boolean;
};

type Ref = HTMLInputElement;

const CheckBox = forwardRef<Ref, CheckBoxProps & ComponentPropsWithRef<'input'>>(
  ({ label, name, className, primary, id = name, type, ...inputProps }, ref) => {
    return (
      <StyledLabel htmlFor={id} className={className}>
        <StyledInput ref={ref} id={id} name={name} type="checkbox" {...inputProps} />
        <StyledBox primary={primary}>{inputProps.checked ? <Checked /> : <Empty />}</StyledBox>
        {label}
      </StyledLabel>
    );
  }
);

CheckBox.displayName = 'CheckBox';

export default memo(CheckBox);
