export const studentenAddress = {
  '@type': 'PostalAddress',
  streetAddress: 'Lange Viestraat 2b',
  addressLocality: 'Utrecht',
  addressRegion: 'Utrecht',
  postalCode: '3511 BK',
  addressCountry: 'NL',
};

export const organizationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Studenten.nl BV',
  url: 'https://studenten.nl/',
  logo: 'https://studenten.nl/assets/studenten-og.png',
  description: 'Temp agency in the Netherlands.',
  email: 'admin@studenten.nl',
  telephone: '+31-30-227-2133',
  address: studentenAddress,
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer service',
    email: 'support@studenten.nl ',
  },
  sameAs: [
    'https://www.facebook.com/www.studenten.nl',
    'https://www.x.com/Studentennl',
    'https://www.youtube.com/@studentenuitzendbureau',
    'https://www.instagram.com/studenten.nl_official/',
  ],
};
