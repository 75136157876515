import { FC, memo } from 'react';
import { Icons } from '@studenten/ui-components';

export const Empty: FC = () => {
  return (
    <Icons.SvgIcon width="24" height="24">
      <rect fill="none" width="23" height="23" x=".5" y=".5" stroke="currentColor" rx="3.5" />
    </Icons.SvgIcon>
  );
};

export default memo(Empty);
