import styled from 'styled-components';

export const PlayButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 64px;
    height: 48px;
    transform: translate(-50%, -50%);
  }

  .video_button {
    fill: #212121;
    fill-opacity: 0.8;
  }

  &:hover {
    cursor: pointer;

    .video_button {
      fill: #ff0000;
      fill-opacity: 1;
    }
  }
`;
