import styled from 'styled-components';

export const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 32px;
`;

export const StyledInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
`;

export const StyledBox = styled.span<{ primary?: boolean }>`
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: -32px;
  color: ${({ theme, primary }) => (primary ? theme.brand.orange : theme.brand.blue)};

  ${StyledInput}:disabled + & {
    color: ${({ theme }) => theme.system.disabled};
  }

  ${StyledInput}:focus + & {
    color: ${({ theme, primary }) => (primary ? theme.brand.darkOrange : theme.brand.darkBlue)};
  }

  ${StyledInput}:checked + & {
    //background-image: url('/assets/icons/checkbox/checked.svg');
  }

  ${StyledInput}:checked:disabled + & {
    //background-image: url('/assets/icons/checkbox/checked_disabled.svg');
  }
`;
