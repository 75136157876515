import { FC, memo } from 'react';
import Image from 'next/image';
import { useUpToLaptop } from 'hooks/useMediaQuery';

import { Wrapper, Content } from './Banner.styles';

type BannerPropsType = {
  imageURL?: string;
  imageUrlMobile?: string;
  backgroundColor?: string;
};

const Banner: FC<BannerPropsType> = ({ imageURL, imageUrlMobile, backgroundColor, children }) => {
  const isMatched = useUpToLaptop();

  const image = isMatched ? imageUrlMobile : imageURL;

  return (
    <Wrapper>
      {image && <Image priority src={image} layout="fill" objectFit="cover" alt='' />}
      {children && <Content backgroundColor={backgroundColor}>{children}</Content>}
    </Wrapper>
  );
};

export default memo(Banner);
