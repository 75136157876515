import styled from 'styled-components';
import { device } from 'constants/device';

export const ContentWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0 108px;
  margin: 0 auto;

  @media ${device.laptop} {
    padding: 0 58px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;
