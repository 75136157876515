import styled from 'styled-components';
import { device } from 'constants/device';

type ContentType = {
  backgroundColor: string | undefined;
};

export const Wrapper = styled.div`
  position: relative;
  min-height: 224px;

  @media ${device.tablet} {
    min-height: 177px;
  }
`;

export const Content = styled.div<ContentType>`
  bacground-color: ${({ theme, backgroundColor = theme.brand.blue }) => backgroundColor};
`;
