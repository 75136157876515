import { forwardRef, ComponentPropsWithRef, memo } from 'react';
import Link, { LinkProps } from 'next/link';

type BaseLinkProps = {
  isExternal?: boolean;
  openNewTab?: boolean;
} & LinkProps &
  ComponentPropsWithRef<'a'>;

type Ref = HTMLAnchorElement;

const BaseLink = forwardRef<Ref, BaseLinkProps>(
  ({ isExternal, openNewTab, passHref, href, rel, target, ...props }, ref) => {
    const _rel = rel || 'noopener';
    const _target = openNewTab ? target || '_blank' : undefined;

    if (isExternal) {
      return <a ref={ref} href={href} rel={_rel} target={_target} {...props} />;
    } else {
      return (
        <Link passHref={passHref} href={href}>
          <a ref={ref} target={_target} rel={rel} {...props} />
        </Link>
      );
    }
  }
);

export default memo(BaseLink);
