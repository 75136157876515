import { FC, memo } from 'react';
import { Icons } from '@studenten/ui-components';

export const Checked: FC = () => {
  return (
    <Icons.SvgIcon width="24" height="24">
      <rect
        width="23"
        height="23"
        x=".5"
        y=".5"
        fill="currentColor"
        stroke="currentColor"
        rx="3.5"
      />
      <path
        fill="#fff"
        d="M8.04 11.293a1 1 0 0 0-1.414 1.414l1.415-1.414Zm2.627 4.04-.707.708a1 1 0 0 0 1.414 0l-.707-.707Zm7.374-5.959a1 1 0 0 0-1.415-1.414l1.415 1.414ZM6.626 12.707l3.334 3.334 1.414-1.414-3.333-3.334-1.415 1.414Zm4.748 3.334 6.667-6.667-1.415-1.414-6.666 6.667 1.414 1.414Z"
      />
    </Icons.SvgIcon>
  );
};

export default memo(Checked);
