import { FC, memo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { createCanonicalUrl } from '../../../utils/helpers';
import { organizationSchema } from '../../../constants/organization';

type MetaTagsProps = {
  title?: string;
  description?: string;
  image?: string;
  keywords?: string;
  preventIndexing?: boolean;
  ogType?: string;
  ldJsonScheme?: Record<string, any>;
  noDefaults?: boolean;
  canonicalUrl?: string | boolean;
  disableWebSiteSchema?: boolean;
};

const isDev = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development';

const webSiteSchema = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: 'Studenten.nl',
  url: 'https://studenten.nl',
  inLanguage: 'NL',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://studenten.nl/vacatures?query={search_term}',
    'query-input': 'required name=search_term',
  },
  publisher: organizationSchema,
};

const MetaTags: FC<MetaTagsProps> = ({
  keywords,
  preventIndexing,
  title: _title,
  description: _description,
  image = `https://${isDev ? 'dev.' : ''}studenten.nl/assets/studenten-og.png`,
  ogType = 'website',
  ldJsonScheme,
  noDefaults,
  canonicalUrl,
  disableWebSiteSchema,
}) => {
  const router = useRouter();
  const url = `https://${isDev ? 'dev.' : ''}studenten.nl${router?.asPath}`;

  const title = noDefaults
    ? _title
    : _title || 'Studenten.nl: Universiteiten, Traineeships en bijbanen';
  const description = noDefaults
    ? _description
    : _description ||
      'Studenten.nl is een online platform voor het vinden van een opleiding, bijbaan of traineeship. Met een aanbod van 3000+ opleidingen en 12000+ bijbanen het Studentenplatform van Nederland.';

  const _canonicalUrl = !canonicalUrl
    ? undefined
    : typeof canonicalUrl === 'string'
    ? createCanonicalUrl(canonicalUrl, true)
    : createCanonicalUrl(router?.asPath, true);

  return (
    <Head>
      {title ? <title>{title}</title> : null}
      {description ? <meta key="description" name="description" content={description} /> : null}
      {keywords && <meta key="keywords" name="keywords" content={keywords} />}

      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />

      <meta key="og:url" property="og:url" content={url} />
      {title ? <meta key="og:title" property="og:title" content={title} /> : null}
      {description ? (
        <meta key="og:description" property="og:description" content={description} />
      ) : null}
      <meta key="og:image" property="og:image" content={image} />
      <meta key="og:site_name" property="og:site_name" content="Studenten.nl" />
      <meta key="og:type" property="og:type" content={ogType} />
      <meta property="og:locale" content="nl_NL" />

      {_canonicalUrl ? (
        <link key="canonical" id="canonical" rel="canonical" href={_canonicalUrl} />
      ) : null}

      {preventIndexing ? (
        <>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </>
      ) : null}
      {ldJsonScheme ? (
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScheme) }}
        />
      ) : null}
      {!disableWebSiteSchema ? (
        <script
          key="website-structured-data"
          type="application/ld+json"
          id="organization-ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(webSiteSchema) }}
        />
      ) : null}
    </Head>
  );
};

export default memo(MetaTags);
