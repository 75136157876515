import { useMediaQuery } from 'react-responsive';
import { device } from '../constants/device';

export const useUpToMobileS = () => useMediaQuery({
  query: device.mobileS,
});

export const useUpToMobileM = () =>
  useMediaQuery({
    query: device.mobileM,
  });

export const useUpToMobileL = () =>
  useMediaQuery({
    query: device.mobileL,
  });

export const useUpToTablet = () =>
  useMediaQuery({
    query: device.tablet,
  });

export const useUpToLaptop = () =>
  useMediaQuery({
    query: device.laptop,
  });

export const useUpToLaptopL = () =>
  useMediaQuery({
    query: device.laptopL,
  });

export const useUpToDesktop = () =>
  useMediaQuery({
    query: device.desktop,
  });
