import { FC, memo, useState, MouseEventHandler } from 'react';

import { PlayButton } from './YTEmbeddedVideo.styles';

export type YTEmbeddedVideoProps = {
  title: string;
  ytVideoId: string;
  width?: number;
  height?: number;
  coverWidth?: number;
  coverHeight?: number;
};

const YTEmbeddedVideo: FC<YTEmbeddedVideoProps> = ({
  width,
  height,
  title,
  ytVideoId,
  coverWidth,
  coverHeight,
}) => {
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowVideo(true);
  };
  if (showVideo) {
    return (
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${ytVideoId}?rel=0&showinfo=0&controls=2&loop=1&autoplay=1`}
        title={title}
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; autoplay; encrypted-media; gyroscope"
      />
    );
  } else {
    return (
      <>
        <picture>
          <source
            srcSet={`https://i.ytimg.com/vi_webp/${ytVideoId}/hqdefault.webp`}
            type="image/webp"
          />
          <img
            width={coverWidth}
            height={coverHeight}
            src={`https://i.ytimg.com/vi/${ytVideoId}/hqdefault.jpg`}
            alt={title}
          />
        </picture>
        <PlayButton onClick={handleOnClick} aria-label="Play video">
          <svg width={68} height={48} version="1.1" viewBox="0 0 68 48">
            <path
              className="video_button"
              d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
              fill="#f00"
            />
            <path className="video_icon" d="M 45,24 27,14 27,34" fill="#fff" />
          </svg>
        </PlayButton>
      </>
    );
  }
};

export default memo(YTEmbeddedVideo);
